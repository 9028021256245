.button {
  box-sizing: border-box;
}

:root {
  --color-text-primary: var(--color-light-slate);
  --color-navy: #0a192f;
  --color-light-slate: #ccd6f6;
  --color-l-navy: #112240;
  --color-light-navy: #233554;
  --color-slate: #8892b0;
  --color-green: #64ffda;
  --color-light-blue: #dadffc;
  --color-card: #c8d5f4;
  --color-accent-orange: #b82e00;
  --color-accent-orange-tint: rgba(184, 46, 0, 0.1);
  --color-green-tint: rgba(100, 255, 218, 0.1);
  --font-mono: "Space Mono";
  --font-sans: "Inter";
  --font-body: clamp(1rem, 0.8852rem + 0.4898vw, 1.375rem);
  --font-heading: 32px;
  --font-h1: clamp(3.2rem, 0.5692rem + 8.238vw, 13.75rem);
  --font-h2-display: clamp(1.875rem, 1.301rem + 2.449vw, 3.95rem);
  --font-xs: 13px;
  --font-m: 16px;
  --font-xl: 20px;
  --fz-xxl: 22px;
  --shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
section {
  opacity: 1;
  position: relative;
}
html,
body {
  min-height: 100vh;
  margin: 0;
  /* background: var(--color-navy); */
}
h1,
h2,
h3,
p {
  font-family: var(--font-sans);
}
h1,
h2,
h3,
a,
li,
.footer-header,
p {
  user-select: none;
}
h1 {
  font-size: var(--font-h1);
}
h2 {
  font-size: var(--font-h2-display);
}
h3 {
  font-size: var(--font-xxl);
}
p {
  font-size: var(--font-m);
}
.App {
  transition: background-color 1s ease;
}

@media only screen and (max-width: 768px) {
  .introduction {
    max-width: unset;
  }
  .sides-wrapper .hexagon-left {
    left: -45vw;
  }
}
