.left {
  position: fixed;
  left: 60px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  z-index: 200;
}
.left::after,
.right::after {
  content: "";
  height: 100px;
  width: 1px;
  margin: auto;
}
li a {
  transition: color 0.3s ease, transform 0.3s ease;
  display: inline-block;
}

li svg {
  width: 20px;
  height: 20px;
}
ul {
  gap: 1rem;
}

.right {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 60px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  text-orientation: mixed;
  gap: 2rem;
  z-index: 200;
}

.right a {
  text-decoration: none;
  font-family: var(--font-sans);
  font-weight: 500;
  font-size: var(--font-xs);
  transition: color 0.3s ease, transform 0.3s ease;
  writing-mode: vertical-lr;
  letter-spacing: 0.1em;
}

.right a:hover,
.left a:hover {
  transform: translateY(-4px);
}
/* .header-element {
  opacity: 0;
} */
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1024px) {
  .right {
    right: 20px;
  }
  .left {
    left: 20px;
  }
}
@media only screen and (max-width: 768px) {
  .right {
    display: none;
  }
  .left {
    display: none;
  }
}
