.slider-container {
  width: 100%; /* Width of the outside container */
  position: relative;
  margin-top: 30px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}
.slider-sticks {
  position: absolute;
  top: 0;
  width: 100%;
  height: 90px;
  background-color: transparent;
  z-index: 0;
  display: flex;
  justify-content: space-between;
}
.stick {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 85px;
  align-items: flex-end;
}
.stick span {
  background-color: red;
  display: block;
  border-radius: 5px;
  width: 3px;
  height: 50px;
  transition: height 0.2s ease, width 0.2s ease;
}
.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem auto 5rem;
  width: 40rem;
}

.content {
  text-align: center;
}
.stick .dark {
  background-color: var(--color-light-navy);
}

span.even {
  height: 30px;
}

span.odd {
  height: 45px;
}

span.sel {
  height: 80px;
  width: 8px;
}

span.hov {
  height: 80px;
  width: 8px;
}

span.middle {
  height: 60px;
}

/* The slider itself */
.slider-input {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 90px; /* Specified height */
  background: transparent; /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  position: absolute;
  cursor: pointer;
  top: 0;
  z-index: 1;
  user-select: none;
}

/* Fix the highligt on touch devices */
input[type="range"] {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}
/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider-input::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 50px; /* Slider handle height */
  background: transparent; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider-input::-moz-range-thumb {
  width: 2px; /* Set a specific slider handle width */
  height: 50px; /* Slider handle height */
  background: transparent; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.stick.dark span {
  background-color: var(--color-light-navy);
}

span::after {
  content: ""; /* Initial content or empty */
  display: flex;
  font-family: "Font Awesome 6 Free";
  font-weight: 400;
  font-size: 30px;
  width: 30px;
  height: 30px;
  color: var(--color-green);
  bottom: -45px;
  margin-left: -10px;
  position: absolute;
  justify-content: center;
  opacity: 0;
  transform: translateY(30px);
  transition: all 0.3s ease;
  /* Add a separate transition for opacity */
}

span.dark::after {
  content: "\f186";
}

span.light::after {
  content: "\f185";
  color: white;
}

.hov::after {
  opacity: 1;
  transform: translateY(0px);
}
@media only screen and (max-width: 900px) {
  .centered-container {
    max-width: 80%;
  }
}

@media only screen and (max-width: 768px) {
  .centered-container {
    max-width: 87%;
  }
}
