header {
  display: flex;
  justify-content: space-between;
  padding: 0rem 4rem;
  align-items: center;
}
ul {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin: 1em 0;
  padding: 0;
  align-items: center;
}

li a {
  text-decoration: none;
  font-family: var(--font-mono);
  font-weight: 500;
  font-size: var(--font-xs);
  cursor: pointer;
}

li {
  list-style-type: none;
  padding: 8px 12px;
  border-radius: 24px;
  transition: all 0.3s ease;
}
.logo:hover {
  fill: var(--color-green-tint);
  cursor: pointer;
}
.sticky-header {
  transition: top 0.5s;
  top: 0;
}
.nav-mobile.open {
  transform: translateX(0);
  transition: all 0.3s ease;
  justify-content: center;
  display: flex;
}

.nav-mobile.closed .sticky-header {
  display: none;
}

.nav-mobile.closed {
  transform: translateX(70vw);
  transition: all 0.3s ease;
}

.overlay.closed {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.overlay.open {
  display: block;
  filter: blur(20px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 6665;
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.scrolled {
  backdrop-filter: blur(10px) brightness(0.7);
  box-shadow: var(--shadow);
}
/* .sticky-header .header-element {
  opacity: 0;
} */
.nav-hamburger svg {
  height: 45px;
  width: 45px;
  padding: 3rem;
}

.nav-hamburger {
  display: none;
  cursor: pointer;
  z-index: 6667;
  right: 0;
  position: fixed;
}
.nav-hamburger.open {
  position: fixed;
}

.nav-mobile {
  display: none;
  height: 100vh;
  width: 70vw;
  max-width: 370px;
  transform: translateX(70vw);
  position: fixed;
  right: 0;
  z-index: 6666;
}
.nav-mobile .logo {
  width: 8rem;
  height: 8rem;
}
.nav-mobile li a {
  font-size: 1rem;
}
.nav-mobile ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.nav-mobile .scrolled {
  backdrop-filter: unset;
  box-shadow: unset;
}
.navigation-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}
.navigation-mobile ul.nav-contact-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.navigation-mobile ul.nav-contact-info a:hover,
.navigation-wrapper a:hover {
  transform: translateY(-4px);
}

.navigation-wrapper a {
  text-decoration: none;
  font-family: var(--font-sans);
  font-size: 1rem;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1024px) {
  .nav-hamburger,
  .nav-mobile {
    display: block;
  }
  .headroom {
    display: none;
  }
  .footer-section {
    transform: none;
  }
}
