.tab-container {
  display: grid;
  grid-template-columns: 14rem auto;
  border: none;
}
.company {
  font-family: var(--font-mono);
  margin-right: 1rem;
  padding: 0.6rem 1rem;
  border-left: 1px solid var(--color-light-navy);
  border-radius: 0 5px 5px 0;
  transition: background-color 0.3s ease;
  cursor: pointer;
  user-select: none;
}

.tabsUi {
  display: block;
}

@media only screen and (max-width: 768px) {
  .tab-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
