.image-frame {
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

.image-frame::after {
  border: 2px solid white;
  top: 20px;
  left: 20px;
  width: 500px;
  height: 500px;
}

.my-technologies {
  display: grid;
  grid-template-columns: repeat(2, minmax(140px, 200px));
  gap: 0px 10px;
  padding: 0px;
  overflow: hidden;
  list-style: none;
  position: relative;
}
.list li {
  font-family: var(--font-mono);
  position: relative;
  padding-left: 20px;
  display: flex;
  align-items: center;
}

body {
  transition: background-color 1s, color 1s;
}

[data-theme="dark"] h1,
[data-theme="dark"] h2,
[data-theme="dark"] h3 {
  color: var(--color-text-primary);
}

[data-theme="light"] h1,
[data-theme="light"] h2,
[data-theme="light"] h3 {
  color: var(--color-navy);
}

[data-theme="dark"] p {
  color: var(--color-slate);
}

[data-theme="light"] p {
  color: var(--color-light-navy);
}

[data-theme="dark"] .index-module_type__E-SaG::after {
  color: var(--color-light-slate);
}
[data-theme="light"] .index-module_type__E-SaG::after {
  color: var(--color-light-navy);
}

[data-theme="dark"] .list li::before {
  color: var(--color-green);
}
[data-theme="light"] .list li::before {
  color: var(--color-light-navy);
}

[data-theme="dark"] .list li {
  color: var(--color-slate);
}
[data-theme="light"] .list li {
  color: var(--color-light-navy);
}
[data-theme="dark"] .hero {
  color: var(--color-light-slate);
}
[data-theme="light"] .hero {
  color: var(--color-l-navy);
}
[data-theme="dark"] .reviews-card {
  background-color: var(--color-l-navy);
}
[data-theme="light"] .reviews-card {
  background-color: var(--color-accent-orange-tint);
}
[data-theme="dark"] .hero-tagline {
  color: var(--color-slate);
}
[data-theme="light"] .hero-tagline {
  color: var(--color-light-navy);
}
[data-theme="dark"] .description-heading {
  color: var(--color-green);
}
[data-theme="light"] .description-heading {
  color: var(--color-light-navy);
}
[data-theme="dark"] .button {
  color: var(--color-green);
  border: 1px solid var(--color-green);
}
[data-theme="light"] .button {
  color: var(--color-accent-orange);
  border: 1px solid var(--color-accent-orange);
}
[data-theme="dark"] .section-header h2:after {
  background-color: var(--color-light-navy);
}
[data-theme="light"] .section-header h2:after {
  background-color: var(--color-accent-orange-tint);
}
[data-theme="dark"] .button:hover {
  background-color: var(--color-green-tint);
}
[data-theme="light"] .button:hover {
  background-color: var(--color-accent-orange-tint);
}
[data-theme="dark"] .highlight-word {
  color: hsl(51, 100%, 50%);
}
[data-theme="light"] .highlight-word {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .highlight-word:hover + #point {
  color: hsl(51, 100%, 50%);
}
[data-theme="light"] .highlight-word:hover + #point {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .sticky-header {
  background-color: rgba(10, 25, 47, 0.85);
}
[data-theme="light"] .sticky-header {
  background-color: rgba(218, 223, 252, 0.85);
}

[data-theme="dark"] li a {
  color: var(--color-light-slate);
}
[data-theme="light"] li a {
  color: var(--color-navy);
}

[data-theme="dark"] li a:hover {
  color: var(--color-green);
}
[data-theme="light"] li a:hover {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .navigation-wrapper a {
  color: var(--color-slate);
}
[data-theme="light"] .navigation-wrapper a {
  color: var(--color-navy);
}
[data-theme="dark"] .navigation-mobile ul.nav-contact-info a:hover,
[data-theme="dark"] .navigation-wrapper a:hover {
  color: var(--color-green);
}
[data-theme="light"] .navigation-mobile ul.nav-contact-info a:hover,
[data-theme="light"] .navigation-wrapper a:hover {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .overlay.open {
  background-color: var(--color-navy);
}
[data-theme="light"] .overlay.open {
  background-color: var(--color-light-slate);
}
[data-theme="dark"] .overlay.open {
  background-color: var(--color-navy);
}
[data-theme="light"] .overlay.open {
  background-color: var(--color-light-slate);
}
[data-theme="dark"] .nav-hamburger {
  color: var(--color-light-slate);
}
[data-theme="light"] .nav-hamburger {
  color: var(--color-navy);
}
[data-theme="dark"] .navigation-mobile {
  background-color: unset;
}
[data-theme="light"] .navigation-mobile {
  background-color: unset;
}
[data-theme="dark"] .nav-mobile {
  background-color: var(--color-light-navy);
}
[data-theme="light"] .nav-mobile {
  background-color: var(--color-card);
}
[data-theme="dark"] .nav-hamburger:hover {
  color: var(--color-green);
}
[data-theme="light"] .nav-hamburger:hover {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .menu:hover .line {
  stroke: var(--color-green);
}
[data-theme="light"] .menu:hover .line {
  stroke: var(--color-accent-orange);
}
[data-theme="dark"] .line {
  stroke: var(--color-light-slate);
}
[data-theme="light"] .line {
  stroke: var(--color-navy);
}
[data-theme="dark"] .menu {
  background-color: transparent;
}
[data-theme="light"] .menu {
  background-color: transparent;
}
[data-theme="dark"] .stick .light {
  background-color: var(--color-green);
}
[data-theme="light"] .stick .light {
  background-color: var(--color-accent-orange-tint);
}
[data-theme="dark"] .right a {
  color: var(--color-slate);
}
[data-theme="light"] .right a {
  color: var(--color-navy);
}
[data-theme="dark"] .company {
  color: var(--color-slate);
}
[data-theme="light"] .company {
  color: var(--color-navy);
}
[data-theme="dark"] .stick .light::after {
  color: gold;
}
[data-theme="light"] .stick .light::after {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .footer-header {
  color: var(--color-green);
}
[data-theme="light"] .footer-header {
  color: var(--color-accent-orange);
}
[data-theme="dark"] .company.selected {
  border-left: 1px solid var(--color-green);
}
[data-theme="light"] .company.selected {
  border-left: 1px solid var(--color-accent-orange);
}
[data-theme="dark"] .company:hover {
  background-color: var(--color-light-navy);
  color: var(--color-green);
}
[data-theme="light"] .company:hover {
  background-color: var(--color-accent-orange-tint);
  color: var(--color-accent-orange);
}
[data-theme="dark"] .company.selected {
  color: var(--color-green);
  background-color: var(--color-light-navy);
}
[data-theme="light"] .company.selected {
  background-color: var(--color-accent-orange-tint);
  color: var(--color-accent-orange);
}
[data-theme="dark"] .user-icon {
  stroke: var(--color-light-slate);
}
[data-theme="light"] .user-icon {
  stroke: var(--color-navy);
}
[data-theme="dark"] .reviewer-name,
[data-theme="dark"] .review-linkedin {
  color: var(--color-light-slate);
}
[data-theme="light"] .reviewer-name,
[data-theme="light"] .review-linkedin {
  color: var(--color-navy);
}
[data-theme="dark"] .reviews-card:hover .reviewer-name,
[data-theme="dark"] .reviews-card:hover .review-linkedin {
  color: var(--color-green);
}
[data-theme="light"] .reviews-card:hover .reviewer-name,
[data-theme="light"] .reviews-card:hover .review-linkedin {
  color: var(--color-accent-orange);
}

[data-theme="dark"] .left::after,
[data-theme="dark"] .right::after {
  background-color: var(--color-slate);
}
[data-theme="light"] .left::after,
[data-theme="light"] .right::after {
  background-color: var(--color-accent-orange-tint);
}
[data-theme="dark"] .right a:hover,
[data-theme="dark"] .left a:hover {
  color: var(--color-green);
}
[data-theme="light"] .right a:hover,
[data-theme="light"] .left a:hover {
  color: var(--color-accent-orange);
}

/* .list[data-theme="light"] li {
  color: red;
}

.list[data-theme="dark"] li {
  color: var(--color-slate);
} */

.list li::before {
  content: "▹";
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  font-size: var(--fz-sm);
  line-height: 12px;
}
.highlight-word {
  display: inline-block;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid transparent;
  text-transform: lowercase;
}
.highlight-word:hover {
  text-transform: uppercase;
  transform: translate(-20px, -10px) rotate(-10deg);
  cursor: default;
  transition: text-transform 5.5s ease;
}
#point {
  display: inline-block;
}

.highlight-word:hover + #point {
  transform: translateX(50vw) scale(2); /* Adjust the value as needed */
  transition: transform 1.2s ease;
  color: #ffd700;
}

.highlight-word:not(:hover) + #point {
  transform: translateX(0);
  transition: transform 1.2s ease;
}
.image-frame img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 5px;
}
.hero {
  font-size: clamp(50px, 8vw, 80px);
  padding: 3rem 0;
}

.hero-tagline {
  font-size: clamp(40px, 8vw, 80px);
}
.description-heading {
  font-weight: 400;
  font-family: var(--font-mono);
  font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
  margin: 0px 0px 30px 4px;
}
.introduction {
  margin: auto;
  position: relative;
  overflow: hidden;
}

.introduction p {
  margin: 40px 0;
  max-width: 700px;
  height: 4rem;
}

.button {
  text-decoration: none;
  padding: 1rem 2rem;
  font-family: var(--font-mono);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: max-content;
  transition: all 0.3s ease;
}

.button:hover {
  cursor: pointer;
}

h1,
h2,
h3 {
  margin: 0;
}

section {
  padding: 3rem 150px;
  margin: 0px auto;
  overflow: hidden;
  z-index: 100;
}

.about {
  display: flex;
  flex-direction: column;
  margin: 10vh auto;
  max-width: 1024px;
}
.section-header h2:after {
  content: "";
  display: block;
  width: 300px;
  height: 1px;
  margin-left: 2rem;
}
.section-header h2 {
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px 0px 40px;
  width: 100%;
  font-size: clamp(26px, 5vw, var(--font-heading));
  white-space: nowrap;
}

.about-me-description {
  display: grid;
  grid-template-columns: 7fr 5fr;
  gap: 5rem;
}
.experience {
  display: block;
  margin: 0 auto;
  padding: 0 20vw;
  position: relative;
  overflow: hidden;
}
.reviews-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px; /* Adjust as needed */
}
.list-of-employees {
  min-height: 30rem;
}
.card-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
/* .intro-anim {
  opacity: 0;
} */

.hexagon {
  position: absolute;
  animation: rotating 50s linear infinite;
  opacity: 0.1;
  z-index: -1;
  filter: blur(5px);
  width: 500px;
  height: 500px;
}

.hexagon img {
  width: 500px;
  height: 500px;
}

.introduction .hexagon {
  bottom: 0;
  right: -11%;
}
.experience .hexagon {
  top: 0;
  left: -11%;
}

/* Typing animation */

@media only screen and (max-width: 1300px) {
  .reviews-wrapper {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1024px) {
  section {
    padding: 80px;
  }
}

@media only screen and (max-width: 768px) {
  section,
  section.experience {
    padding: 3rem;
  }
  .reviews-wrapper .reviews-card {
    margin: 0;
  }
  .list-of-employees {
    min-height: unset;
  }
  .centered-container .content {
    width: 100%;
  }
  .centered-container .content .button {
    width: 100%;
  }
  .about-me-description {
    grid-template-columns: 1fr;
  }
  .image-frame img {
    max-width: 70%;
  }
  .introduction p {
    height: 6rem;
  }
}

@media only screen and (max-width: 425px) {
  .introduction .hexagon {
    bottom: 0;
    right: -51%;
  }
  .experience .hexagon {
    bottom: 0;
    left: -51%;
  }
  section {
    padding: 2rem;
  }
}

@media only screen and (max-width: 400px) {
  .introduction p {
    height: 8rem;
  }
  .about-me-description {
    display: block;
  }
  .reviews-wrapper .reviews-card {
    padding: 17px;
  }
  .description-heading {
    padding-top: 3rem;
  }
  .hero {
    padding: 1rem 0;
  }
  .image-frame {
    padding-top: 5rem;
  }
}

@media only screen and (max-width: 380px) {
  section {
    padding: 1rem;
  }
}
