.reviews-card {
  padding: 2rem;
  margin: 1rem;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 5px;
}
.reviews-card:hover {
  transform: translate(-5px, -5px) rotate(3deg);
}
.user-icon {
  width: 40px;
  height: 40px;
}
.review-linkedin {
  padding: 1rem;
}
.review-linkedin svg {
  width: 20px;
  height: 20px;
}
@media only screen and (max-width: 425px) {
  .reviews-card:hover {
    transform: translate(0px, -5px);
  }
}
